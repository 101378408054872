<template>
  <a-form-model ref="form" :model="form" v-bind="$formItemLayout">
    <a-form-model-item
      label="名称"
      prop="name"
      :rules="{
        required: true,
        message: '名称不能为空',
        trigger: 'blur'
      }"
    >
      <a-input v-model.trim="form.name" placeholder="请填写名称" :maxLength="25" />
    </a-form-model-item>
    <a-form-model-item
      label="服务类型："
      prop="type"
      :rules="{
        required: true,
        message: '分类不能为空',
        trigger: 'blur'
      }"
    >
      <h-select v-model="form.type" allowClear placeholder="请选择">
        <a-select-option v-for="typeInfo in typeOptions" :key="typeInfo.value" :value="typeInfo.value">
          {{ typeInfo.label }}
        </a-select-option>
      </h-select>
    </a-form-model-item>
    <a-form-model-item
      label="介绍："
      prop="introduction"
      :rules="{
        required: true,
        message: '介绍不能为空',
        trigger: ['change', 'blur']
      }"
    >
      <div style="position:relative">
        <a-input
          v-model="form.introduction"
          placeholder="请输入内容"
          :maxLength="500"
          :autoSize="{ minRows: 5, maxRows: 5 }"
          type="textarea"
        />
        <div style="position:absolute;bottom:-5px;right:1px;">
          <span style="background:#fff">{{ form.introduction.length }}/500</span>
        </div>
      </div>
    </a-form-model-item>
    <a-form-model-item
      label="服务特色："
      prop="characteristic"
      :rules="{
        required: true,
        message: '服务特色不能为空',
        trigger: ['change', 'blur']
      }"
    >
      <div style="position:relative">
        <a-input
          v-model="form.characteristic"
          placeholder="请输入内容"
          :maxLength="500"
          :autoSize="{ minRows: 5, maxRows: 5 }"
          type="textarea"
        />
        <div style="position:absolute;bottom:-5px;right:1px;">
          <span style="background:#fff">{{ form.characteristic.length }}/500</span>
        </div>
      </div>
    </a-form-model-item>
    <a-form-model-item
      label="视频介绍："
      prop="videoUrl"
      :rules="{
        required: false,
        message: '请上传视频介绍',
        trigger: 'blur'
      }"
    >
      <div>
        <single-upload :accept="['mp4']" :limit="1024 * 1024 * 200" @success="handleVideoUrl"></single-upload>
        <a-row v-if="form.videoUrl" style="margin:8px 0;line-height:normal" type="flex" align="middle">
          <a-col flex="1" style="text-overflow: ellipsis;white-space:nowrap;overflow:hidden;">
            <a
              @click="handlePreviewVideo(form.videoUrl)"
              target="blank_"
              style="text-overflow: ellipsis;white-space:nowrap;overflow:hidden;"
            >
              <a-icon type="file" style="margin-right:8px" />{{ form.videoUrl }}
            </a>
          </a-col>
          <a-col class="error-color" style="margin-left:16px;cursor:pointer" @click="form.videoUrl = ''">
            <a-icon type="delete" class="error-color" />
          </a-col>
        </a-row>
        <div class="disabled-color" style="line-height:1.5">
          提示：MP4格式文件，大小不超过200MB
        </div>
      </div>
    </a-form-model-item>
  </a-form-model>
</template>

<script>
import { cloneDeep } from 'lodash'
import { SingleUpload } from '@/components/_heath'
import { SERVICE_MANAGER_TYPE } from '@/store/dictionary'
import { previewVideo } from '@/utils/util'

export default {
  components: {
    SingleUpload
  },
  data() {
    return {
      form: {
        name: undefined,
        type: '',
        introduction: '',
        characteristic: '',
        videoUrl: ''
      },
      typeOptions: SERVICE_MANAGER_TYPE
    }
  },
  methods: {
    initData(form) {
      const { id, name, type, introduction, characteristic, videoUrl } = form
      this.form = {
        id,
        name,
        type,
        introduction,
        characteristic,
        videoUrl
      }
    },
    validate() {
      const {
        $refs: { form }
      } = this
      return new Promise((resolve, reject) => {
        form.validate((valid, err) => {
          if (valid) {
            resolve(valid)
            return
          }
          reject(err)
        })
      })
    },
    formatForm() {
      const ret = cloneDeep(this.form)
      return { ...ret }
    },
    handleCoverUrl(url) {
      const {
        $refs: { form }
      } = this
      this.form.coverUrl = url
      form.clearValidate('coverUrl')
    },
    // 上传的文件信息
    handleVideoUrl(uploadInfo) {
      this.form.videoUrl = uploadInfo.url
    },
    //
    handlePreviewVideo(url) {
      previewVideo(url)
    }
  }
}
</script>

<style lang="less" scoped></style>
